<template>
  <div class="cart-list__attr-block">
    <div
      ref="cartItemAttr"
      class="cart-list__gd-info"
    >
      <div 
        v-if="showSwitchToMall"
        v-expose="{ id: '1-7-3-65', data: { sku_code: item.product.sku_code } }"
        v-tap="{ id: '1-7-3-66', data: { sku_code: item.product.sku_code } }"
        class="change-mall"
        :class="{'change-mall__disabled': isBatchActivePage }"
        @click="handleSwitchMall"
      >
        <div class="change-mall__text">
          {{ switchToMallText }}
        </div>
      </div>
      <div
        v-if="['editor'].includes(editorStatus) || showReselectBtnNew"
        ref="sizeAttr"
        class="gd-color-size"
        :class="{editable: isValid && !isAddBuyGoodsOrGiftGoods, disabled: isBatchActivePage || isCustomGood, readonly: item.isNewcomerItem, 'reselect-size': showReselectBtnNew}"
        :tabindex="isBatchActivePage ? -1 : 0"
        role="button"
        @click="editOpen"
      >
        <!-- loading -->
        <div
          v-if="loading"
          class="edit-loading"
        >
          <div class="la-ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          v-else
          class="content-info"
        >
          <!-- 当前尺码无货，其他尺码有货，展示成reselect按钮 -->
          <div
            v-if="showReselectBtnNew"
            class="reselect-size__btn-contain"
          >
            <span>{{ language.SHEIN_KEY_PC_16381 }}</span>
            <i>
              <sui_icon_more_down_12px_1 size="12px" />
            </i>
          </div>
          <!-- 尺码 -->
          <template v-else>
            <div
              v-if="skuSaleAttrText"
              class="gd-sale-attr"
            >
              <div
                v-if="isShowColorImg"
                class="gd-color"
              >
                <img
                  class="color-img active"
                  :src="transformImg({img: item.product.color_image})"
                />
              </div>
              <span>{{ skuSaleAttrText }}</span>
            </div>
            <template v-else>
              <span class="gd-size">
                <em
                  v-if="item.product && item.product.attr_value_std_name"
                  class="local-size"
                  style="display:inline-block"
                >
                  {{ item.product.attr_value_std_name }} /
                </em>
                <em v-if="item.attr">{{ item.attr.attr_value }}</em>
                <template v-if="item.attr_value_location && item.product.brand !== 'Luvlette'">
                  <template v-for="locationsize in item.attr_value_location.size_rule_list">
                    <span
                      v-if="locationsize.name == item.attr_value_en"
                      :key="locationsize.name"
                      style="display: inline-block;"
                    >
                      ({{ locationsize.correspond }})
                    </span>
                  </template>
                </template>
              </span>
              <!-- 当地尺码 -->
              <template v-if="item.product.color_image && item.relatedProductIds != '' && item.attr && item.attr.attr_value">
                /
              </template>
              <!-- 颜色 -->
              <div
                v-if="item.product.color_image && item.relatedProductIds != ''"
                class="gd-color"
              >
                <img
                  class="color-img active"
                  :src="transformImg({img: item.product.color_image})"
                />
              </div>
            </template>
            <!-- 编辑箭头 -->
            <i
              v-if="isValid && !isAddBuyGoodsOrGiftGoods"
            >
              <sui_icon_more_down_12px_1 size="12px" />
            </i>
          </template>
        </div>
      </div>
      <div v-show="showFindSimilar">
        <span
          class="find-similar"
          :class="{disabled: isBatchActivePage || isCustomGood}"
          :da-event-expose="isBatchActivePage? '' : '1-7-3-4'"
          :da-event-click="isBatchActivePage? '' : '1-7-3-5'"
          :data-goods-id="item.product.goods_id"
          :data-sku="item.product.goods_sn"
          data-activeFrom="cart"
          :data-isOutOfStock="editorStatus == 'find-similar' ? 1 : 0"
          @click="handleShowSimilarModal(item)"
        >{{ language.SHEIN_KEY_PC_16963 }}</span>
      </div>
      <CustomDetailEntry
        v-if="isCustomGood && haveCustomData && !IS_RW && !isSoldOut"
        :item="item"
      />
      <ClientOnly>
        <BehaviorLabelsSwiper
          v-if="needSwiperInit"
          class="user-behavior"
          :language="language"
          :labels="behaviorLabel"
          :need-swipe="!isBatchActivePage && behaviorLabelNeedSwipe"
          :intersection-swiper="intersectionSwiper"
        />
      </ClientOnly>
    </div>
    <div
      v-if="reselectTips"
      class="gd-editable reselect-similar"
    >
      {{ reselectTips }}
    </div>
    <CartItemProductTag 
      v-if="soldoutIds.indexOf(item.id) == -1 && (item.promotionTag && item.promotionTag.typeIdText || showQuickShip || showFreeShipping || item.multiPromotionTags || showEvoluShein)"
      :language="language"
      :show-quick-ship="showQuickShip"
      :show-free-shipping="showFreeShipping"
      :item="item"
      page="cart"
      :abt-params="ABTParams"
      :index="index"
      :time-stamp="countDownData"
      :is-batch-active-page="isBatchActivePage"
      :show-evolu-shein="showEvoluShein"
      @jump="jump"
    />
    <div
      v-if="item.isNewcomerItem"
      class="gd-editable newcomer"
    >
      {{ language.SHEIN_KEY_PC_27125 }}
    </div>
    <div
      v-if="item.isLoseNewcomerItem"
      class="gd-editable newcomer"
    >
      {{ language.SHEIN_KEY_PC_27124 }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import SimilarPopover from '../../../../common/similar_products'
import { daEventCenter } from '../../../../../services/eventCenter'
import CartItemProductTag from './CartItemProductTag.vue'
import { handleUpdateAndUpdateAttrErr, scrollToCartItem, isValidCartItem, getItemSaleText } from '../../../utils'
import { htmlDecode as _htmlDecode, template, transformImg } from '@shein/common-function'
import { updateAttrMall } from 'public/src/pages/cart_new/utils/fetcher.js'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import BehaviorLabelsSwiper from './BehaviorLabelsSwiper.vue'
import CustomDetailEntry from '../CustomDetailEntry.vue'
import { sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
let cartQuickView = null
if(typeof window !== 'undefined') {
  import('../../../utils/cartQuickView').then(_ => {
    cartQuickView = _.default
  })
}

export default {
  name: 'CartListAttrBtn',
  components: {
    CartItemProductTag,
    BehaviorLabelsSwiper,
    CustomDetailEntry,
    sui_icon_more_down_12px_1
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
    showEvoluShein: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      loading: false,
      showM2W: false,
      fetching: false,
      needSwiperInit: false,
      intersectionSwiper: false,
    }
  },
  computed: {
    ...mapState(['locals', 'language', 'soldoutIds', 'cartSumQuantity', 'cartInfo', 'cartList', 'isPlatform', 'ABTParams', 'integrityPromotionInfo', 'blackFridayImgGoods', 'mallInfo']),
    ...mapGetters(['showQuickShipTag', 'cartMallSwitchGoods', 'cartSaleAttrSwitchGoods']),
    IS_RW() {
      return this.locals.IS_RW || gbCommonInfo.IS_RW
    },
    isValid(){
      return isValidCartItem(this.item, this.isPlatform)
    },
    isAddBuyGoodsOrGiftGoods () {
      return this.item.product.product_promotion_info && this.item.product.product_promotion_info.some(promo => [1, 2].includes(+promo.promotion_product_type)) ? true : false
    },
    // 是否展示色块图
    isShowColorImg() {
      return this.item.product.main_sale_attr_show_mode == 2 && this.item.product.color_image && this.item.product.relatedColor?.length > 0
    },
    skuSaleAttrText () {
      return getItemSaleText({
        defaultlocalsize: this.ABTParams?.defaultlocalsize?.param?.defaultlocalsize,
        skuSaleAttr: this.item.product?.sku_sale_attr ?? [],
        sizeRuleList: this.item?.attr_value_location?.size_rule_list ?? [],
        userLocalSizeCountry: this.locals.USER_LOCAL_SIZE_COUNTRY,
      }).join(' / ')
    },
    showReselectBtn(){
      if (this.editorStatus !== 'reselect') return false
      if (this.item.isSheinClubGiftItem && !this.item.isInvalid) return true
      return !this.isAddBuyGoodsOrGiftGoods
    },
    showReselectBtnNew() {
      return this.showReselectBtn && !this.item.isLoseNewcomerItem && !this.showSwitchToMall
    },
    isSwitchItem() {
      return (this.cartMallSwitchGoods && this.item.isMallSwitchGoods) || (this.cartSaleAttrSwitchGoods && this.item.isSaleAttrSwitchGoods)
    },
    showFindSimilar() {
      if (this.isSwitchItem) return false
      if (this.editorStatus == 'find-similar') return true
      return this.showReselectBtn && !this.item.isSheinClubGiftItem
    },
    reselectTips() {
      if (this.showSwitchToMall) return this.language.SHEIN_KEY_PC_32188
      if (this.isSoldOut && this.item.isSheinClubGiftItem) {
        return this.item.isInvalid ? this.language.SHEIN_KEY_PC_31013 : this.language.SHEIN_KEY_PC_30889
      }
      if (this.showReselectBtnNew && !this.showSwitchToMall) return this.language.SHEIN_KEY_PC_16775
      return false
    },
    editorStatus () {
      const { item, soldoutIds } = this
      let result = ''
      if (!this.isValid && soldoutIds.includes(item.id)) {
        result = 'find-similar'
      } else if (this.isValid && soldoutIds.includes(item.id)) {
        result = 'reselect'
      } else {
        const localSize = item.product && item.product.attr_value_std_name
        const trueSize = item.attr?.attr_value
        const moreImgs = item.product.color_image && item.relatedProductIds?.length
        if (localSize || trueSize || moreImgs || this.skuSaleAttrText) {
          result = 'editor'
        } else {
          result = ''
        }
      }
      return result
    },
    showQuickShip(){
      return this.showQuickShipTag && this.soldoutIds.indexOf(this.item.id) == -1 && this.item.quick_ship == 1 ? true : false
    },
    showFreeShipping() {
      return this.item.freight_free_rule_id > 0
    },
    countDownData(){
      if (this.item.promotionTag.typeId === 4 && this.item.promotionTag.promotionLogoType === 8) { // 付费会员赠品
        const target = this.cartInfo.promotion_member_info?.find?.(f => f.promotion_id === this.item.promotionTag.promotionId)
        return target?.member_equity_end_time || ''
      }
      if (
        this.item.promotionTypeIds?.includes(3) ||
        this.item.promotionTypeIds?.includes(8) ||
        (this.item.promotionTypeIds?.includes(10) && [1, 4, 5, 6].includes(Number(this.item.flashType)))
      ) {
        let promotion = this.item.product.product_promotion_info.find(item => [3, 8, 10].includes(+item.type_id))
        if(promotion) {
          let targetPromotion = this.integrityPromotionInfo[promotion.promotion_id]
          if(targetPromotion.is_count_down){
            return targetPromotion.end_time
          }
        }
      }
      if(this.item.product.insurable == 1 && this.item.product.insurable_time) {
        let end = this.item.product.insurable_time || 0
        if(end){
          end = (end + '').length >= 13 ? end : end * 1000
        }
        const now = (new Date()).getTime()
        const diffTime = end - now
        const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24)
        return  diffDays < 3 ? this.item.product.insurable_time : ''
      }
      return ''
    },
    behaviorLabel() {
      if (this.soldoutIds?.includes(this.item?.id)) return null
      const showBehaviorLabel = this.blackFridayImgGoods[this.item?.product?.goods_id]?.showBehaviorLabel
      return BehaviorLabel.getNormalLabel(showBehaviorLabel)
    },
    behaviorLabelNeedSwipe() {
      return this.ABTParams?.UserBehaviorTips?.param?.CartBehaviorAction == 'Show'
    },
    isCustomGood() {
      return Boolean(Object.keys(this.item.product?.customization_info || {}).length)
    },
    haveCustomData() {
      const customInfo = this.item.product?.customization_info?.preview || {}
      return customInfo?.images?.length || customInfo?.text?.length || customInfo?.effects?.length
    },
    isSoldOut() {
      return this.soldoutIds.indexOf(this.item.id) != -1
    },
    showSwitchToMall() {
      return this.item.isMallSwitchGoods
    },
    switchToMallText() {
      if (this.showSwitchToMall) {
        const mallInfo = this.mallInfo.find(item => item.mall_code === this.item.prioritySwitchMall?.mall_code)
        return this.language.SHEIN_KEY_PC_32189.replace('{0}', mallInfo?.language_mall_name)
      }
      return ''
    }
  },
  mounted(){
    this.initIntersectionObserverInstance()
  },
  methods: {
    ...mapMutations(['updateField', 'changeAppendageDrawerStatus']),
    ...mapActions(['asyncUpdateCartInfo', 'fetchModifyCartCheckStatus', 'handleFlashSaleOverScene', 'updateField']),
    async handleUpdateCallBack(res, item) {
    // update 回调
      if (res.code == 0) {
        let originCarts = JSON.parse(JSON.stringify(this.cartList))
        await this.asyncUpdateCartInfo({ cartInfo: res.info.info })
        this.reflashGetCoupon()
        if((item.is_checked == 1 && item.id == res.info.cart.id) || (item.id != res.info.cart.id)){
          this.handleFlashSaleOverScene({ editCartId: res.info.cart.id, carts: res.info.info?.carts, originCarts: originCarts })
        }
        if(item.mall_code != res.info.cart.mall_code || item.id != res.info.cart.id){
          this.$nextTick(() => {
            scrollToCartItem(res.info.cart.id, true)
          })
        }
        GB_analysis_obj.editUpdate(item, res)
      } else{
        let errorMessage = handleUpdateAndUpdateAttrErr(res, this.language, item)
        errorMessage.msg && SMessage({
          message: errorMessage.msg,
          type: errorMessage.times ? 'error' : 'warning',
          offset: 180,
          duration: errorMessage.times || 3000
        })
      }

      // 接入 apollo 控制是否调用 check 接口
      if(res.CART_AUTO_CHECKOUT !== 'ON' && (item.is_checked == 0 || (res.code == 0 && item.id != res.info.cart.id))){
        // let promotionIds = item.promotionIds
        let appendIds = this.item.appendIds
        let reqParam = {
          operation_type: 1,
          cart_id_list: [],
          editCartId: res.info?.cart?.id || item.id
        }
        if (res.code == 0 && item.id != res.info.cart.id){
          let targetCartItem = res.info.info.carts?.find(cartItem => cartItem.id == res.info.cart.id)
          if(targetCartItem) {
            // promotionIds = targetCartItem.product?.product_promotion_info?.map(promotionItem => promotionItem.promotion_id) || []
            targetCartItem.is_checked == 0 && reqParam.cart_id_list.push(targetCartItem.id)
            appendIds = targetCartItem.appendIds
          }
        } else {
          reqParam.cart_id_list.push(item.id)
        }

        reqParam.cart_id_list = reqParam.cart_id_list.concat(appendIds || [])
        reqParam.cart_id_list.length && this.fetchModifyCartCheckStatus(reqParam)
      }
    },
    async handleSwitchMall() {
      if (this.isBatchActivePage) return
      const item = this.item
      const data = {
        id: this.item.id,
        goods_id: this.item.product.goods_id,
        quantity: Math.min(this.item.quantity, this.item.prioritySwitchMall.sku_qty),
        mall_code: this.item.prioritySwitchMall.mall_code,
        sku_code: this.item.product.sku_code,
        attrs: this.item.product.sku_sale_attr.filter(item => item.is_main == 0).map(item => ({
          attr_id: item.attr_id,
          attr_value_id: item.attr_value_id
        }))
      }
      this.updateField({ key: 'bigLoading', value: true })
      updateAttrMall(data).then((res) => {
        this.handleUpdateCallBack(res, item)
      }).finally(() => {
        this.updateField({ key: 'bigLoading', value: false })
      })
    },
    // 领券接口更新
    reflashGetCoupon () {
      this.updateField({
        key: 'isReflashGetCouponFetch',
        value: true
      })
    },
    handleShowSimilarModal: function(item) {
      if (this.isBatchActivePage) return
      SimilarPopover.showModal(item.product, {
        similarFrom: 'out_of_stock',
      })
    },
    async editOpen (extraParams = { }) {
      if(this.isCustomGood) {
        this.$message({
          htmlString: this.language.SHEIN_KEY_PC_27388,
          type: 'warning',
        })
        return
      }
      if (this.isBatchActivePage) return
      const _this = this
      const index = this.index
      const item = _this.item
      if (
        !(this.showReselectBtn && this.item.isSheinClubGiftItem) &&
        (
          !this.isValid || this.isAddBuyGoodsOrGiftGoods || item.isNewcomerItem
        )
      ) return false
      if (this.item.isSheinClubGiftItem) { // 当前非售罄会员赠品无法编辑
        this.changeAppendageDrawerStatus({
          status: 'club-gift',
          promotionId: this.item.promotionTag.promotionId,
        })
        return
      }
      this.loading = true
      const imgRatio = await getImageRatio(item.product.goods_img)
      cartQuickView.showDetail({ goods_id: item.product.goods_id, index, imgRatio, goods_sn: item.product.goods_sn, mall_code: item.mall_code, ...extraParams, showPromotionTagInfo: false }, () => {
        _this.loading = false
        daEventCenter.triggerNotice({
          daId: '1-7-3-13',
          extraData: {
            sku_id: item.product.goods_sn,
          }
        })
        // 打开qv成功
      }, 'cartEdit', async res => {
        this.handleUpdateCallBack(res, item)
      }, {
        id: item.id,
        qvtype: 'cartEdit',
        quantity: item.quantity,
        attrs: item.attrs ? JSON.parse(item.attrs) : '',
        sku_sale_attr: item.product?.sku_sale_attr || [],
        mall_code: item.mall_code
      })

    },
    ClickAddToWishlist () {},
    transformImg: transformImg,
    template: template,
    htmlDecode: function (text){
      return _htmlDecode({ text: text })
    },
    async jump(promotionTag, url_prams = '', analysisData){
      if (promotionTag.typeId === 4 && promotionTag.promotionLogoType === 8) {
        window.open(`${gbCommonInfo.langPath}/user/prime?productsource=clubgiftcart`, '_blank')
        return
      }
      let promotionItem = this.integrityPromotionInfo[promotionTag.promotionId] || {}
      promotionItem.scId = promotionItem?.goods?.sc_id || ''
      const newWin = window.open('', '_self')
      const url = await PromotionUtils.promotionLink({
        ...promotionItem,
        url_prams,
        entry_from: 'entry`cart',
        src_module: analysisData?.data?.src_module || '',
        src_identifier: analysisData?.data?.src_identifier || '',
        src_tab_page_id: analysisData?.data?.src_tab_page_id || '',
      })
      newWin.location = url
    },
    initIntersectionObserverInstance() {
      this.IntersectionObserverInstance = new IntersectionObserver(
        entries => {
          const intersectionRatio = entries[0].intersectionRatio

          if (intersectionRatio > 0) {
            this.needSwiperInit = true
            this.intersectionSwiper = true
          } else {
            this.intersectionSwiper = false
          }
        },
        {
          thresholds: [0, 1]
        }
      )

      this.IntersectionObserverInstance.observe(this.$refs.cartItemAttr)
    },
  }
}
</script>

<style lang="less" scoped>
.cart-list__gd-info{
  flex:1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.15;
  //position: relative;
  // padding-top: 35px;
  >:not(:last-child):not(:empty){
    margin-right: 8px;
  }
  .gd-sale-attr{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    position: relative;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .change-mall {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #222;
    font-size: 13px;
    font-weight: 400;
    border-radius: 18px;
    padding: 4px 10px;
    cursor: pointer;
    line-height: 1.15;
    margin-bottom: 8px;
    &__text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      position: relative;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &__disabled {
      opacity: 0.3;
    }
  }
}
.gd-editable{
  color: @sui_color_unusual;
  font-size: 13px;
  &.reselect-similar{
    margin-bottom: 8px;
    .clamp(1);
  }
  &.newcomer{
    position: relative;
    z-index: 1;
    margin-bottom: 8px;
  }
}
.gd-color-size{
  white-space: nowrap;
  display: inline-block;
  padding: 4px 10px;
  color: @sui_color_gray_dark2;
  cursor: default;
  background: #F2F2F2;
  border-radius: 18px;
  font-size: 13px;
  border: 1px solid #F2F2F2;
  margin-bottom: 8px;
  .edit-loading{
    line-height: 14px;
  }
  
  .gd-color{
    display: inline-block;
    margin-right: 4px;
    img{
      width: 13px;
      height: 13px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 100%;
      vertical-align: middle;
      margin-bottom: 1px;
    }
  }
  .gd-size{
    color: #222;
    .local-size{
      display: inline-block;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: text-bottom;
    }
  }
  i{
    color: #222;
    font-weight: bold;
    font-size: 12px;
    position: relative;
    margin-left: 4px;
  }
  &.editable{
    cursor: pointer;
    &:hover{
      border: 1px solid #222;
    }
  }
  &.disabled{
    cursor: default;
    opacity: .3;
    &:hover{
      border: 1px solid #e5e5e5;
    }
  }
  &.readonly{
    cursor: default;
    &:hover{
      border: 1px solid #e5e5e5;
    }
  }
  &.reselect-size{
    white-space: normal;
    color: @sui_color_gray_dark1;
    font-weight: bold;
    .reselect-size__btn-contain{
      .flexbox();
      span{
        flex: 1;
        .clamp(1);
        .margin-r(5px);
      }
    }
  }
  .content-info{
    display:flex;
    align-items: center;
  }
}
.find-similar {
  display: inline-block;
  margin-bottom: 8px;
  padding: 4.5px 10px;
  color: @sui_color_gray_dark1;
  font-weight: bold;
  cursor: pointer;
  background: #F2F2F2;
  border-radius: 14px;
  font-size: 13px;
  border: 1px solid #F2F2F2;
  text-decoration: none;
  &:hover {
    border: 1px solid #222222;
  }
  &.disabled{
    opacity: .3;
    cursor: default;
  }
}
.user-behavior {
  margin: 3px 0 8px;
}
</style>
