const cartDialogComponentMap = {
  fullGiftShow: 'cartFullGiftsDrawIsLoaded',
  addBuyShow: 'cartAddBuyDrawIsLoaded',
  buyGiftShow: 'cartBuyGiftDrawIsLoaded',
  batchActive: 'batchActiveIsLoaded',
  clubGiftShow: 'cartClubGiftDrawIsLoaded'
}
const statusType = {
  'full-gift': 'fullGiftShow',
  'add-buy': 'addBuyShow',
  'add-buy-all': 'addBuyShow',
  'buy-gift': 'buyGiftShow',
  'club-gift': 'clubGiftShow'
}
const mutations = {
  updateLanguage(state, payload) {
    const { language } = payload
    state.language = language
  },
  updateCartInfo(state, payload) {
    const { cartInfo } = payload
    state.cartInfo = cartInfo
  },
  updateCartList(state, payload) {
    const { cartList } = payload
    state.cartList = cartList
  },
  updateIntegrityPromotionInfo(state, payload) {
    state.integrityPromotionInfo = payload
  },
  setStoreSelectsInfo(state, payload) {
    state.storeSelectsInfo = payload
  },
  updateField(state, payload){
    const { key, value } = payload
    if(cartDialogComponentMap[key] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[key]]){
      state.asyncComponentLoadedStatus[cartDialogComponentMap[key]] = true
    }
    state[key] = value
  },
  updateMallInfo(state, payload){
    const { mallInfo } = payload
    state.siteMallList = mallInfo || []
    if(mallInfo?.length > 1){
      state.isPlatform = true
    } else {
      state.isPlatform = false
    }
  },
  updateAddForCoupon(state, payload = {}) {
    state.addForCouponConfig = payload
  },
  /**
   * 
   * @param {*} state
   * @param {*} payload {
   *  promotionId: 活动id,
   *  status: 'fullGiftShow' | 'addBuyShow' | 'buyGiftShow' | 'clubGiftShow',
   *  fromType: 'pick' | 'addItem'
   *  }
   */
  changeAppendageDrawerStatus (state, payload) {
    const { status, promotionId, fromType = 'pick' } = payload
    if(cartDialogComponentMap[statusType[status]] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[statusType[status]]]){
      state.asyncComponentLoadedStatus[cartDialogComponentMap[statusType[status]]] = true
    }
    state.appendageDrawer = {
      promotionId,
      fromType,
      fullGiftShow: statusType[status] == 'fullGiftShow',
      addBuyShow: statusType[status] == 'addBuyShow',
      buyGiftShow: statusType[status] == 'buyGiftShow',
      clubGiftShow: statusType[status] == 'clubGiftShow'
    }
  },
  resetAppendageDrawerStatus (state) {
    state.appendageDrawer = {
      promotionId: '',
      fromType: '',
      fullGiftShow: false,
      addBuyShow: false,
      buyGiftShow: false,
      clubGiftShow: false
    }
  },
  updateBottomActivityConfig (state, payload) {
    state.bottomActivityConfig = {
      ...state.bottomActivityConfig,
      ...payload,
    }
  },
  updateLocals(state, payload) {
    state.locals = Object.assign(state.locals, payload ?? {})
  }
}

export default mutations
