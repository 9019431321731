<template>
  <div
    class="cart-list__item-img"
    @click="clickGoodsList()"
  >
    <div
      class="gd-img"
      :class="{ 'soldout-opacity': soldoutOpacity }"
      @click="clickGoodsImage()"
    >
      <!-- 商品图 -->
      <div class="i-img-wrapper">
        <img
          v-if="isFirstPage"
          :src="baseCutImg"
          class="i-img"
        />
        <img
          v-else
          class="i-img lazyload not-fsp-element"
          :data-src="getMainImageUrl({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains })"
          :src="lazyImg"
          :data-design-width="1440"
          data-exp="cutTo=240#max=360"
        />
        <div class="i-img-wrapper__mask not-fsp-element"></div>
      </div>
      <template v-if="!hideCorner">
        <!-- 折扣角标 -->
        <div
          v-show="showDiscountCorner && isFlashOrPremiumMember && item.product.unit_discount > 0"
          class="discount-corner"
          :class="getDiscountClass"
        >
          {{ lang!='il' ? '-' : '' }}{{ item.product.unit_discount }}%
        </div>
        <!-- 系列角标 -->
        <img
          v-if="blackFridayImg"
          :data-src="transformImg({img: blackFridayImg})"
          :src="lazyImg"
          class="product-series-flag black-friday-flag lazyload not-fsp-element"
        />
        <img
          v-else-if="seriesFlag && isFirstPage"
          :src="baseCutSeriesFlagImg"
          class="product-series-flag not-fsp-element"
        />
        <img
          v-else-if="seriesFlag"
          :data-src="transformImg({img: seriesFlag})"
          :src="lazyImg"
          class="product-series-flag lazyload not-fsp-element"
        />
        <!-- 折扣角标 -->
        <div
          v-if="!isFlashOrPremiumMember && item.product.unit_discount != '0' && showDiscountCorner"
          class="discount-corner"
          :class="[
            cartDiscountLogoA ? 'a-color' : 'default-color',
            { 'cart__discount': !cartDiscountLogoA && cartDiscount || cartPromotionDiscount || specialCouponDiscount }
          ]"
        >
          <template v-if="cartDiscount">
            <div>
              {{ lang!='il' ? '-' : '' }}{{ item.product.unit_discount }}%
            </div>
            <div class="cart__discount-sale">
              {{ lang!='il' ? '-' : '' }}{{ item.product.sale_discount }}%
            </div>
          </template>
          <template v-else>
            <span>{{ lang!='il' ? '-' : '' }}{{ item.product.unit_discount }}%</span>
          </template>
        </div>
      </template>
      <template v-if="!hideStock">
        <!-- 若有外部配置传入,则使用外部配置的底部库存展示标签 config.quantity -->
        <ImgStockBar
          :item="item"
          :config="{
            quantity: config.quantity
          }"
          :ctx="{
            language,
            soldoutIds,
            locals,
            flashSaleType,
            flashSaleItemNum,
            cartDiscountLogoA,
          }"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// CartItemStock 中的样式为全局样式, 保证其他模块cart-item__insufficientStock的正常使用需引入
import CartItemStock from './CartItemStock.vue'
import ImgStockBar from './newList/ImgStockBar.vue'
import { transformImg } from '@shein/common-function'
import { getMainImageUrl } from 'public/src/pages/cart_new/utils/index.js'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index.js'

const { forceHttp } = gbCommonInfo
export default {
  name: 'CartListItemImg',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CartItemStock,
    ImgStockBar,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    config: {
      type: Object,
      default() {
        return {}
      }
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
    hideCorner: Boolean,
    hideStock: Boolean,
    goodsDetailUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['language', 'soldoutIds', 'soldoutItems', 'blackFridayImgGoods', 'locals']),
    ...mapGetters(['underPriceAbtValue', 'cartDiscountLogoA', 'isSuggested', 'isNewCartDiscountStyle', 'customServiceImgDomains', 'thirdPartyServiceImgDomains']),
    lang() {
      return this.locals.lang || gbCommonInfo.lang
    },
    GBCssRight() {
      return this.locals.GB_cssRight || gbCommonInfo.GB_cssRight
    },
    IS_RW() {
      return this.locals.IS_RW || gbCommonInfo.IS_RW
    },
    soldoutOpacity() {
      return  this.soldoutItems.map(item => item.id).includes(this.item.id)
    },
    isSoldout() {
      return this.soldoutIds.includes(this.item.id)
    },
    cartDiscount(){
      const { product = {}, promotionTypeIds = [] } = this.item
      return promotionTypeIds.includes(3) && product.sale_discount && product.sale_discount != product.unit_discount
    },
    cartPromotionDiscount(){
      const { product = {}, promotionTypeIds = [] } = this.item
      return (this.underPriceAbtValue && promotionTypeIds.includes(30)) && product.unit_discount
    },
    specialCouponDiscount() {
      const { product = {}, promotionTypeIds = [] } = this.item
      return promotionTypeIds.includes(32) && product.unit_discount
    },
    isFlashOrPremiumMember(){
      const { isSheinClubGiftItem, promotionTypeIds = [] } = this.item
      return promotionTypeIds.includes(10) || promotionTypeIds.includes(29) || isSheinClubGiftItem ? true : false
    },
    blackFridayImg() {
      return this.blackFridayImgGoods[this.item.product.goods_id]?.promoLabel?.src || ''
    },
    seriesFlag() {
      let { series_info_list = [], corner_brand_right, corner_brand_left, corner_series_right,  corner_series_left } = this.item.product

      // 1.没有系列角标，返回普通品牌角标
      if (!series_info_list || !series_info_list.length) {
        return this.GBCssRight ? corner_series_right || corner_brand_right : corner_series_left || corner_brand_left
      }

      // 2.针对系列角标排序
      series_info_list.sort((a, b) => {
        if (a.series_type === b.series_type) {
          return b.logo_show_position - a.logo_show_position
        }
        return b.series_type - a.series_type
      })
      
      return this.GBCssRight ? series_info_list[0].corner_series_right : series_info_list[0].corner_series_left
    },
    baseCutImg() {
      // 没有cutImg方法或者使用原图时，不裁剪  
      let url = this.getMainImageUrl({ item: this.item, IS_RW: this.IS_RW, customServiceImgDomains: this.customServiceImgDomains, thirdPartyServiceImgDomains: this.thirdPartyServiceImgDomains })
      if (!this?.cutImg) {
        return url.replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp')
      }
      return this.cutImg(url, 1440, { cutTo: 240, max: 360 })
    },
    baseCutSeriesFlagImg() {
      // 没有cutImg方法或者使用原图时，不裁剪  
      let url = this.seriesFlag
      if (!this?.cutImg) {
        return url.replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp')
      }
      return this.cutImg(url, 0, {})
    },
    lazyImg() {
      return this.locals.IMG_LINK?.LAZY_IMG || gbCommonInfo.LAZY_IMG
    },
    getDiscountClass() {
      if (this.cartDiscountLogoA) {
        return this.item.promotionTypeIds.includes(10) ? 'a-color' : 'a-discount-corner-premium'
      } else {
        return this.item.promotionTypeIds.includes(10) ? [1, 4, 5].includes(this.item.flashType) ? 'activity-flash' : 'new-person-flash' : 'discount-corner-premium'
      }
    },
    flashSaleItemNum () {
      // wiki.pageId=843279127
      let promotionFlash = this.item.product.product_promotion_info.filter(item => item.type_id == 10)
      const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
      const realTimeInventory = +this.item.realTimeInventory || 0
      const flashNum =  stock - sold_num
      const minNum = Math.min(realTimeInventory, flashNum)
      const maxNum = Math.max(realTimeInventory, flashNum)
      return minNum > 0 ? minNum : maxNum
    },
    // 转换闪购类型, 0: 非闪购, 1: 普通闪购, 2: 新人闪购, 3: 特殊闪购, 4: 分人群专区闪购, 5: 不分人群专区闪购, -1: 直播闪购(PC无直播闪购)
    flashSaleType() {
      return this.item.promotionTypeIds.includes(10) ? this.item.flashType : (this.item.promotionTypeIds.includes(11) ? -1 : 0)
    },
    showDiscountCorner(){
      if (this.item.isGiftItem) return // 赠品不显示折扣角标

      // is_inversion是否倒挂，仅fr/pl站点合规展示 pageId=1360565216
      if(!this.isSuggested) return true
      if(this.isSuggested == 'special_de') {
        return  [1, 3, 5].includes(this.item.suggestedPriceType)
      }
      return this.item.is_inversion == 1 ? false : true
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  methods: {
    async gotoDetail() {
      const {
        item: {
          mall_code,
          product: { goods_img, goods_url_name, goods_id, cat_id },
        }
      } = this
      const url = await getProductDetailUrl({
        imgSrc: goods_img,
        goods_url_name,
        goods_id,
        cat_id,
        mall_code,
      })
      window.open(forceHttp + url, '_blank')
    },
    clickGoodsImage(){
      if (this.isBatchActivePage) return
      if (this.isSoldout && this.item.isSheinClubGiftItem) {
        this.$toast(this.item.isInvalid ? this.language.SHEIN_KEY_PC_31013 : this.language.SHEIN_KEY_PC_30889)
        return
      }
      if(this.goodsDetailUrl){
        window.open(forceHttp + this.goodsDetailUrl, '_blank')
        return
      }
      this.gotoDetail()
    },
    clickGoodsList(){
      if (this.isBatchActivePage) return
      this.$emit('clickGoodsList')
    },
    transformImg: transformImg,
    getMainImageUrl,
  },
  emits: ['clickGoodsList']
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.cart-list__item-img{
  width: 120px;
  height: 120px;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  .discount-corner {
    position: absolute;
    text-align: center;
    text-transform: lowercase;
    direction: ltr /*rtl:ignore*/;
    font-size: 12px;
    line-height: 18px;
    /*sh:begin*/   
      width: 39px;
      top: 5px;
      left:0px/*rtl:ignore*/;
    /*rw:begin*/
    padding: 0 2px;
    top: 0px;
    left:0px/*rtl:ignore*/; 
    font-family: Adieu-Bold, Adieu;
    font-weight: bold;
    &.default-color {
      color: @color_white_mirror;
      /*sh:begin*/
      background: #222;
      /*rw:begin*/
      background: @sui_color_main;
      color:@sui_color_white;
    }
    &.a-color {
      background: #FA6338;
      color: #fff;
      /*rw:begin*/
      background: #FC4070;
    }
    &.activity-flash {
      background: @sui_color_flash;
      color: #222;
      /*rw:begin*/
      color: @sui_color_white;
    }
    &.cart__discount {
      background: @sui_color_promo;
      height: auto;
      /*sh:begin*/
      width: 40px;
      line-height: 20px;
      div {
        text-align: center;
      }
      /*rw:begin*/
      color: @sui_color_white;
      background: @sui_color_main;
    }
    .cart__discount-sale {
      text-decoration: line-through;
      opacity: 0.8;
      /*rw:begin*/
      font-family: Adieu-Regular, Adieu;
      font-weight: 400;
    }
    &.discount-corner-premium{
      background: #FFE2D3;
      color: #873C00;
    }
    &.a-discount-corner-premium {
      background: #873C00;
      color: #fff;
    }
    &.new-person-flash{
      background: @sui_color_discount;
      color: @sui_color_white;
    }
  }
  .i-img{
    width: 100%;
  }
  .product-series-flag {
    position: absolute;
    top: 4px;
    right: 4px /*rtl:ignore*/;
    width: 32px;
    height: auto;
    &.black-friday-flag{
      top: 0;
      right: 0 /*rtl:ignore*/;
    }
  }
}
.cart-list__item-img {

  .gd-img {
    height: 100%;
    .i-img-wrapper{
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &__mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .03);
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .i-img{
        width: 100%;
      }
    }
  }
}
.soldout-opacity > *:not(.cart-item__insufficientStock) {
  opacity: 0.3;
}
</style>
