<template>
  <div
    class="warehouse-container"
    :class="{'warehouse-container__seft':showFirstStoreTitle}"
  >
    <div class="warehouse-content">
      <slot></slot>
      <div class="warehouse-title">
        <span
          class="cart__mall-name"
          :title="mallInfo.mall_name"
        >
          {{ mallInfo.mall_name }}
        </span>
        <s-label
          v-if="isLocalShipment"
          type="success"
          style="font-weight: normal"
        >
          {{ language.SHEIN_KEY_PC_22078 }}
        </s-label>
      </div>
    </div>
    <platform-shipping
      :mall-code="mallCode"
      class="mall-section-item"
    />
    <full-promotion-info-tip
      :mall-code="mallCode"
      :is-single-mall="false"
      root-class-name="mall-section-item"
      class="mall-section-item"
    />
    <!-- 全场满赠商品（付费会员） -->
    <full-gift-header
      v-if="sheinClubFullGiftPromotion"
      class="mall-full-promotion mall-section-item"
      :integrity-promotion-info="sheinClubFullGiftPromotion"
      :is-single-mall="false"
    />
    <!-- 全场满赠商品 -->
    <full-gift-header
      v-if="fullGiftPromotion"
      class="mall-full-promotion mall-section-item"
      :integrity-promotion-info="fullGiftPromotion"
      :is-single-mall="false"
    />
    <!-- 全场加价购商品 -->
    <full-add-on-header
      v-if="fullAddOnPromotion"
      class="mall-full-promotion mall-section-item"
      :integrity-promotion-info="fullAddOnPromotion"
      :is-single-mall="false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import platformShipping from './platformShipping.vue'
import fullPromotionInfoTip from '../carttop/fullPromotionInfoTip.vue'
import fullGiftHeader from '../list/fullGiftHeader.vue'
import fullAddOnHeader from '../list/fullAddOnHeader.vue'
// import getCoupon from '../getCoupon/index.vue'

export default {
  name: 'PlatformWarehouseHeader',
  components: {
    platformShipping,
    // getCoupon,
    fullPromotionInfoTip,
    fullGiftHeader,
    fullAddOnHeader
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    showFirstStoreTitle: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState(['language', 'mallList', 'integrityPromotionInfo']),
    mallInfo(){
      return this.mallList.find(item => item.mall_code == this.mallCode) || {}
    },
    sheinClubFullGiftPromotion() {
      return Object.values(this.integrityPromotionInfo).find(item => [4].includes(+item.type_id) && item.promotion_logo_type === 8 && item.mall_code == this.mallCode)
    },
    fullGiftPromotion() {
      return Object.values(this.integrityPromotionInfo).find(item => [4].includes(+item.type_id) && item.promotion_logo_type !== 8 && item.mall_code == this.mallCode)
    },
    fullAddOnPromotion(){
      return Object.values(this.integrityPromotionInfo).find(item => [22].includes(+item.type_id) && item.mall_code == this.mallCode)
    },
    // mall标签 1：兜底、2：平台、3：自营、4：跨境、5：本土，逗号分隔
    // 有“本土”标签，则展示Local Shipment标签
    isLocalShipment(){
      return this.mallInfo.mall_tag?.split(',').includes('5')
    }
  },
}
</script>

<style lang="less">
.warehouse-container{
  margin-top: 5px;
  .warehouse-title{
    font-weight: 900;
    color: #222222;
    font-size: 20px;
    display: flex ;
    flex: 1;
    align-items: center;
    .cart__mall-name{
      font-size: 18px;
      font-weight: 700;
      .clamp(1);
      margin-right: 4px;
    }
  }
  .warehouse-content{
    display: flex;
    align-items: center;
    padding: 16px 16px 8px;
    background-image: url("/she_dist/images/cart/sui_img_mallbg_checkout@2x-79fcffa40a.png") /* rtl: url("/she_dist/images/cart/sui_img_mallbg_checkout_ar@2x-1a97bcbc9d.png") */;
    background-size: 100%;
  }
  .mall-full-promotion{
    padding: 0;
  }
  .platform-header-section{
    padding: 12px 12px 12px 16px;
    .operation{
      color: #222222;
      font-weight: 700;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .mall-section-item{
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 1px;
    /* stylelint-disable-next-line selector-max-specificity */
    section.section.promotion-sec {
      padding: 12px 0;
    }
    .full-activity-contain {
      margin-top: unset;
    }
    // &:last-child {
    //   padding-bottom: unset;
    // }
  }
  .mall-section-item:not(.shipping-header-container) {
    background:@sui_color_promo_bg;
  }
}
</style>
