<template>
  <div 
    class="col-sm-4 c-check c-check__cart-right"
    :class="{'c-check__new-cart-right': !IS_RW}"
  >
    <div class="c-check__cart-summary fsp-element">
      <div class="check-title">
        <h4
          tabindex="0"
          class="cart-summary-title"
        >
          {{ language.SHEIN_KEY_PC_14764 }}
        </h4>
      </div>
      <DiscountDetailsContent
        v-if="isShowDiscountDetails"
        :language="language"
        :locals="locals"
        :abt-info="ABTParams"
        :cart-info="cartInfo"
        :cart-list="cartList"
        :carts-id="cartsId"
        :soldout-ids="soldoutIds"
        :cart-item-map="cartItemMap"
        :integrity-promotion-info="integrityPromotionInfo"
        :checked-item-ids="priceDetailCheckedItemIds"
        :flash-sale-group-list="flashSaleGroupList"
        :cart-discount-logo-a="cartDiscountLogoA"
        :show-prime-vip-over-quota="showPrimeVipOverQuota"
        :under-price-abt-value="underPriceAbtValue"
        :prime-vip-over-quota-tip="primeVipOverQuotaTip"
        :is-safari="isSafari"
        :is-suggested="isSuggested"
        :is-expose="true"
        @update-checked-item-ids="updateCheckedItemIds"
        @fetch-modify-cart-check-status="fetchModifyCartCheckStatus"
      />

      <div class="c-order-summary">
        <div
          v-cloak
          style="position: relative;"
        >
          <div
            class="summary-item total-item"
            tabindex="0"
          >
            <div class="price-label">
              {{ priceLabel }}
            </div>
            <discount-details-v2
              class="summary-discount-details not-fsp-element"
              append-to-body
              :language="language"
              :locals="locals"
              :abt-info="ABTParams"
              :cart-info="cartInfo"
              :cart-list="cartList"
              :carts-id="cartsId"
              :soldout-ids="soldoutIds"
              :cart-item-map="cartItemMap"
              :integrity-promotion-info="integrityPromotionInfo"
              :checked-item-ids="checkedItemIds"
              :flash-sale-group-list="flashSaleGroupList"
              :cart-discount-logo-a="cartDiscountLogoA"
              :show-prime-vip-over-quota="showPrimeVipOverQuota"
              :under-price-abt-value="underPriceAbtValue"
              :prime-vip-over-quota-tip="primeVipOverQuotaTip"
              :is-safari="isSafari"
              :is-suggested="isSuggested"
              :show-popver="showDetailEnter"
              :is-expose="isExposePriceDetail"
              @update-checked-item-ids="updateCheckedItemIds"
              @fetch-modify-cart-check-status="fetchModifyCartCheckStatus"
            >
              <div class="summary-price">
                <div
                  v-if="crossedPrice && (isSuggested == 'special' || isSuggested == 'special_de')"
                  class="summary-price-crossed"
                  :title="isSuggested == 'special' ? language.SHEIN_KEY_PC_30549 : language.SHEIN_KEY_PC_31069"
                >
                  <span
                    class="crossed-price-text"
                  >
                    {{ isSuggested == 'special' ? language.SHEIN_KEY_PC_30549 : language.SHEIN_KEY_PC_31069 }}
                  </span>
                </div>
                <div>
                  <span
                    v-if="crossedPrice"
                    class="crossed-price-text crossed-price"
                  >{{ crossedPrice.amountWithSymbol }}</span>
                  <span
                    class="total"
                    style="font-weight: bold;"
                    :class="{'total-red': showTotalRed}"
                  >
                    <template v-if="isSafari">{{ totalPrice.amountWithSymbol || 0 }}</template>
                    <number-roll
                      v-else
                      :amount="totalPrice.amountWithSymbol || 0"
                      :box-width="16"
                      :box-top="1"
                    />
                  </span>
                </div>
              </div>
            </discount-details-v2>
          </div>
          <div
            v-if="lang == 'au'"
            class="summary-item gst-item"
          >
            <span></span> <span>Incl GST</span>
          </div>
          <div
            v-if="taxIncludeInfo"
            class="summary-tax-info not-fsp-element"
          >
            {{ taxIncludeInfo }}
          </div>
          <!-- 优惠明细 -->
          <template v-if="!isSuggested || finallyPrice">
            <LazyHydrationWrapper
              :when-triggered="isIdle"
            >
              <discount-details-v2
                class="summary-discount-details not-fsp-element"
                append-to-body
                :language="language"
                :locals="locals"
                :abt-info="ABTParams"
                :cart-info="cartInfo"
                :cart-list="cartList"
                :carts-id="cartsId"
                :soldout-ids="soldoutIds"
                :cart-item-map="cartItemMap"
                :integrity-promotion-info="integrityPromotionInfo"
                :checked-item-ids="checkedItemIds"
                :flash-sale-group-list="flashSaleGroupList"
                :cart-discount-logo-a="cartDiscountLogoA"
                :show-prime-vip-over-quota="showPrimeVipOverQuota"
                :under-price-abt-value="underPriceAbtValue"
                :prime-vip-over-quota-tip="primeVipOverQuotaTip"
                :is-safari="isSafari"
                :is-suggested="isSuggested"
                :is-expose="isExposePriceDetail"
                @update-checked-item-ids="updateCheckedItemIds"
                @fetch-modify-cart-check-status="fetchModifyCartCheckStatus"
              >
                <span
                  v-if="finallyPrice"
                  class="cart-checkout__discountDetails-text"
                >
                  {{ template(finallyPrice.amountWithSymbol, language.SHEIN_KEY_PC_30553 + ' {0}') }}
                </span>
              </discount-details-v2>
            </LazyHydrationWrapper>
          </template>
          <!-- 积分提示 -->
          <p
            v-if="totalPoints"
            class="point-tip"
            tabindex="0"
            :aria-label="`${language.SHEIN_KEY_PC_14748} ${totalPoints} ${language.SHEIN_KEY_PC_15742}`"
          >
            <prime-vip-icon
              v-if="primePoints && IS_RW"
              top="2"
              :public-cdn="publicCdn"
            />
            <span>{{ language.SHEIN_KEY_PC_14748 + ' ' }}</span>
            <span>
              <span :class="[primePoints ? (primeIconV2 ? 'point-prime-vip_a' : 'point-prime-vip') : 'point-red']"> {{ totalPoints }}</span>
              <template v-if="rewardPoints && (getDoublePoints || primePoints)">
                <span
                  class="prime-point"
                  :class="[primePoints ? (primeIconV2 ? 'point-prime-vip_a' : 'point-prime-vip') : 'point-red']"
                >({{ getDoublePoints && primePoints ? getDoublePoints + rewardPoints : rewardPoints }}+&nbsp;
                  <Icon
                    v-if="primePoints && !IS_RW"
                    name="sui_icon_club_logo_fill_14px"
                    size="16px"
                    :color="primeIconV2 ? '#873C00' : '#C96E3F'"
                  />
                  {{ primePoints ? primePoints : getDoublePoints }})
                </span>
              </template>
              <s-popover
                class="not-fsp-element"
                placemen="bottom-end"
                trigger="hover"
                :append-to-body="false"
                :prop-style="{
                  width: '240px',
                  top: '32px',
                  lineHeight: '14px',
                  right:locals.GB_cssRight ? 'auto' : 0,
                  left:locals.GB_cssRight ? 0 : 'auto'
                }"
              >
                <span class="point-tip-content">
                  <span v-if="!primePoints">{{ language.SHEIN_KEY_PC_15761 }}</span>
                  <span v-if="!primePoints">{{ language.SHEIN_KEY_PC_22344 }}</span>
                  <span v-if="primePoints && IS_RW">{{ template(pointGrantAmountInfo.cycle_used_grant_amount ,pointGrantAmountInfo.cycle_grant_amount, language.SHEIN_KEY_PC_23895) }}</span>
                  <span v-if="primePoints && !IS_RW">{{ template(pointGrantAmountInfo.cycle_used_grant_amount ,pointGrantAmountInfo.cycle_grant_amount,primePoints, language.SHEIN_KEY_PC_26101) }}</span>
                </span>
                <template #reference>
                  <span
                    class="point-shein c-icon-tip"
                    href="javascript:;"
                  >
                    {{ language.SHEIN_KEY_PC_15742 }}
                    <sui_icon_doubt_14px_1
                      class="icon_doubt"
                      size="14px"
                    />
                  </span>
                </template>
              </s-popover>
            </span>
          </p>
        </div>


        <div
          :class="[
            'check-btn',
            checkoutButtonPopoverType == 'incentive' ? 'incentive-check-btn' : '',
          ]"
        >
          <component
            :is="popoverName"
            :tip="language.SHEIN_KEY_PC_25310"
            :checkout-button-popover-type="checkoutButtonPopoverType"
            @set-popover-type="setPopoverTypeWithPriority"
          >
            <s-button
              :type="['primary','H54PX']"
              :class="[
                'j-cart-check',
                checkoutButtonPopoverType == 'incentive' ? 'sweep-light' : '',
                incentiveBtnType ? 'incentive-button' : '',
              ]"
              role="button"
              width="100%"
              :disabled="!cartInfo.cartSumQuantity"
              @click="goCheckout"
            >
              <span
                v-show="!checking"
                class="checkout-btn-content"
              >
                {{ controlCheckoutNameText ? checkoutNameText : checkoutNameDefaultText }}
              </span>
              <CheckoutButtonIncentiveText
                :control-checkout-name-text="controlCheckoutNameText"
              />
              <div
                v-show="checking"
                :class="['la-ball-pulse', 'la-ball-pulse-white']"
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
              <ClientOnly>
                <AutoStretchSwiperTag
                  class="free-shipping-tip not-fsp-element"
                  :show-free-shipping-tag="showFreeShipping"
                  :show-quick-ship-tag="showQuickShip"
                  stretch-direction="rtl"
                  :language="language"
                />
              </ClientOnly>
            </s-button>
          </component>

          <!-- 优惠券提示 -->
          <p
            class="coupon-point-tip"
            tabindex="0"
            v-html="htmlDecode({text: language.SHEIN_KEY_PC_14551})"
          ></p>
        </div>

        <CartRuleInfo v-if="showCartRuleInfo" />
      </div>
    </div>
    <LazyHydrationWrapper>
      <div
        class="accept-bank j-accept-bank"
      >
        <p>{{ language.SHEIN_KEY_PC_14761 && language.SHEIN_KEY_PC_14761.replace(':','') }}</p>
        <div class="bank-img">
          <img
            v-for="item in logoList"
            :key="item.url"
            :src="cutImg(item.url, 0, {})"
            class="c-img__con-m fsp-element"
          />
        </div>
      </div>
    </LazyHydrationWrapper>
    <unPaidOrderTip
      v-if="CartUnpaidOrder"
      v-show="showUnPaidOrder"
      :order="unPaidOrderInfo.orderData"
      :language="language"
      @update-order="handleUpdateOrder"
      @jump-to-order="handleViewOrder"
    />
    <ClientOnly>
      <SummaryCancelOrder
        v-if="showCancelOrderTip"
        :language="language"
      />
    </ClientOnly>
    <ClientOnly>
      <s-dialog
        v-if="IS_RW"
        v-model:visible="showFlashSaleTip"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <template #title>
          {{ language.SHEIN_KEY_PC_23128 }}
        </template>
        <div class="flashSale-over-tip">
          <div
            v-for="item in flashSaleOverTip"
            :key="item.flashSaleType"
            class="tip-item"
            v-html="item.tip"
          ></div><br />
        </div>
        <template #footer>
          <s-button
            :type="['primary']"
            @click="showFlashSaleTip=false"
          >
            {{ language.SHEIN_KEY_PC_15216 }}
          </s-button>
        </template>
      </s-dialog>
      <CartFlashSaleDialog
        v-else
        :show-dialog="showFlashSaleTip"
        :flash-sale-data="flashSaleOverTip"
        @close-flash-sale-dialog="showFlashSaleTip=false"
        @handle-filter="handleFilter"
      />
      <LowPriceUserIndex
        v-if="showLowPriceUser"
        :show-low-price-user="showLowPriceUser"
        :show-free-shipping="showFreeShipping"
        :show-quick-ship="showQuickShip"
        @close-low-price-user-evt="closeLowPriceUserEvt"
        @emit-click-checkout-evt="emitClickCheckoutEvt"
      />
      <IncentiveProductList
        :is-show="showIncentiveProductListDialog"
        @close="handleIncentiveProductListDialog"
      />
    </ClientOnly>
  </div>
</template>
<script>
import { getToCheckoutUrl } from 'public/src/pages/cart_new/utils/getToCheckoutUrl'
import { getNoCheckStockCondition } from 'public/src/pages/cart_new/utils/getNoCheckStockCondition'
const { IS_RW, CART_FILTER_CHECKOUT_TIP_SWITCH } = gbCommonInfo
import cartLoginView from '../utils/cartLoginView'
import { CheckoutTipCache, showFilterItem } from '../utils/cartFilterLabels.js'
import primeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { abtservice } from '../../../services/abt'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { handleOrderOrSingleIsMeetOrOver, cartEmphasizePromotionAll } from 'public/src/pages/cart_new/utils/index.js'
import { template, htmlDecode, transformImg, getQueryString } from '@shein/common-function'
import { nanoid } from 'nanoid'
import UserInfoManager from 'public/src/services/UserInfoManager/index.js'
import schttp from 'public/src/services/schttp'
import { defineAsyncComponent } from 'vue'
import apiReport from 'public/src/pages/common/apiReport'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { Icon, sui_icon_doubt_14px_1 } from '@shein-aidc/icon-vue3'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import AutoStretchSwiperTag from './AutoStretchSwiperTag.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { queryLatestUnPaidOrderInfo } from '../utils/fetcher.js'
import { stringifyQueryString } from '@shein/common-function'
import unPaidOrderTip from './unPaidOrderTip/unPaidOrderTip.vue'
import { web_checkout_button_jump, web_checkout_button_click, web_checkout_button_error } from '../utils/metricTagsConfig.js'
import SummaryCancelOrder from './cancelOrderTip/SummaryCancelOrder.vue'

daEventCenter.addSubscriber({ modulecode: '1-7-2' })


export default {
  name: 'SummaryVue',
  components: {
    sui_icon_doubt_14px_1,
    primeVipIcon,
    CartRuleInfo: defineAsyncComponent(() => import(/* webpackChunkName: "cart-rule-info" */'./CartRuleInfo/index.vue')),
    LowPriceUserIndex: defineAsyncComponent(()=> import('public/src/pages/cart_new/components/lowPriceUser/index.vue')),
    CartFlashSaleDialog: defineAsyncComponent(() => import('./cartFlashSale/CartFlashSaleDialog.vue')),
    Icon,
    discountDetailsV2: defineAsyncComponent(() => import('./checkoutDiscountDetails/index.vue')),
    LazyHydrationWrapper,
    AutoStretchSwiperTag,
    ClientOnly,
    unPaidOrderTip,
    CheckoutIncentivePopover: defineAsyncComponent(() => import('./popover/checkoutIncentivePopover.vue')),
    CheckoutFilterPopover: defineAsyncComponent(() => import('./popover/checkoutFilterPopover.vue')),
    IncentiveProductList: defineAsyncComponent(() => import('./cartCheckoutIncentive/IncentiveProductList.vue')),
    CheckoutButtonIncentiveText: defineAsyncComponent(() => import('./cartCheckoutIncentive/CheckoutButtonIncentiveText.vue')),
    SummaryCancelOrder,
    DiscountDetailsContent: defineAsyncComponent(() => import('./checkoutDiscountDetails/DiscountDetailsContent.vue')),
  },
  data: function() {
    return {
      // logoList: [],
      checking: false, //是否正在验证可以checkout
      showFlashSaleTip: false,
      flashSaleOverTip: [],
      isSafari: false,
      showLowPriceUser: false,
      abtTest: {},
      lowerPriceParams: {},
      checkoutButtonPopoverType: '',
      filterLabelPopoverCache: null,
      checkedItemIds: [],
      isShowUnPaidOrder: true,
      popoverContentId: `j-cart-checkout-${nanoid()}`,
      isIdle: false
    }
  },
  computed: {
    ...mapState(['logoList', 'locals', 'isPlatform', 'cartInfo', 'language', 'cartList', 'lastCheckStockTime', 'integrityPromotionInfo', 'soldoutIds', 'ABTParams', 'flashSaleOverLimit', 'couponInfo', 'filterType', 'taxInclusiveTip', 'cartsId', 'cartItemMap', 'targetCouponInfo', 'unPaidOrderInfo', 'loginStatus', 'goodsFilterLabels', 'cartQsTagInfo', 'showIncentivePopover', 'showIncentiveProductListDialog', 'showIncentiveTimes', 'inducementSortList', 'incentivePopoverSwitchTimer', 'incentivePopoverKeepTimer', 'incentivePopoverContent']),
    ...mapGetters(['primeVipOverQuotaTip', 'mallShippingAnalysisParam', 'cartCheckoutPromotionMap', 'flashSaleGroupList', 'showQuickShipTag', 'underPriceGroupList', 'underPriceAbtValue', 'CouponAddonStatus', 'checkoutFreeShippingState', 'cartDiscountLogoA', 'isSuggested', 'showPrimeVipOverQuota', 'incentiveTimeConfig', 'incentiveBtnType', 'isExposePriceDetail']),
    // canCheckout(){ //购物车是否可以下单
    //   return !this.soldoutIds.length
    // },
    publicCdn () {
      return this.locals.PUBLIC_CDN
    },
    lang() {
      return this.locals.lang
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    showCartRuleInfo() {
      return this.locals.SiteUID === 'br' && this.ABTParams?.brTaxhint?.param?.isSwitchOn == '1'
    },
    totalPointMultiple(){
      return this.cartInfo.totalPointMultiple
    },
    totalPoints(){
      return this.cartInfo.totalPoints
    },
    rewardPoints(){
      return this.cartInfo.rewardPoints
    },
    getDoublePoints(){
      return this.cartInfo.getDoublePoints
    },
    checkoutNameText () {
      return this.template(this.controlCheckoutNameText, this.language?.SHEIN_KEY_PC_25554)
    },
    checkoutNameDefaultText () {
      if (!this.checkedQuantity) return this.language.SHEIN_KEY_PC_14543
      return this.language.SHEIN_KEY_PC_14543 + ` (${this.checkedQuantity})`
    },
    checkedQuantity(){
      let cartCheckedNum = 0
      this.cartList.filter(item => item.is_checked == 1).forEach(cartItem => {
        cartCheckedNum += cartItem.quantity
      })
      return cartCheckedNum
    },
    primePoints(){
      return this.cartInfo.prime_points
    },
    pointGrantAmountInfo(){
      return this.cartInfo.point_grant_amount_info || {}
    },
    totalPrice(){
      return (this.isSuggested ? this.cartInfo.total_price_before_coupon : this.cartInfo.totalPrice) || {}
    },
    flashSaleOverLimitType(){
      let overTypeSet = new Set()
      Object.values(this.flashSaleOverLimit).forEach(item => {
        if(item.isChecked && item.overType){
          overTypeSet.add(item.overType)
        }
      })
      return overTypeSet.size ? overTypeSet.size == 2 ? 0 : overTypeSet.has('singleOver') ? 'single' : 'total' : 0
    },
    openControlLowPriceUser () {
      // 临时后端兼容性措施, 低客拦截abt
      let {
        ControlLowpriceUser = '',
      } = this.ABTParams?.LowpriceUser?.param || {}
      if(!ControlLowpriceUser || ControlLowpriceUser == 'off') return 0
      // 校验阈值与差值, 并规范返回值,0不进行拦截, 1触发强拦截, 2触发弱拦截
      const { action, threshold_price, residue_price } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      if(action == 1 || action == 2) {
        return Number(residue_price?.amount || 0) > 0 && Number(threshold_price?.amount || 0) > 0 ? action : 0
      } else return 0
    },
    controlCheckoutNameText () {
      const { action, threshold_price, residue_price } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      // checkout文案展示调整
      let {
        ControlLowpriceUser = '',
        ControlCheckoutname = '',
        // ControlForcedInterception = ''
      } = this.ABTParams?.LowpriceUser?.param || {}

      // 临时后端兼容性措施, 低客拦截abt
      if(!ControlLowpriceUser || ControlLowpriceUser == 'off') return ''

      if(!action) return ''
      else if((action == 1 || action == 2) && Number(residue_price?.amount || 0) > 0 && Number(threshold_price?.amount || 0) > 0) {
        if(ControlCheckoutname == 'on') return threshold_price?.amountWithSymbol || ''
        return ''
      } else return ''
    },
    // 是否是弱拦截
    openWeakIntercept () {
      return this.ABTParams?.LowpriceUser?.param?.ControlForcedInterception != 'on'
    },
    buttonNameClickType () {
      const { threshold_price } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      const amount = Number(threshold_price?.amount || 0)
      if (this.openControlLowPriceUser) {
        // return interception == 'on' ? `power_checkout_${Number(strongUsdAmount)}` : `lower_checkout_${Number(weakUsdAmount)}`
        return this.openControlLowPriceUser == 1 ? `power_checkout_${amount}` : `lower_checkout_${amount}`
      }
      return 'checkout'
    },
    taxIncludeInfo() {
      if((this.ABTParams?.TaxSwitch?.param?.TaxSwitchValue || '')?.split(',')?.includes('cart') && this.totalPrice.amount > 0) {
        return this.taxInclusiveTip || ''
      } else return ''
    },
    primeIconV2() {
      return this.cartDiscountLogoA
    },
    showFreeShipping() {
      return this.checkoutFreeShippingState
    },
    showQuickShip() {
      let checkedCarts = this.cartList.filter(item => item.is_checked == 1)
      const isQuickShipCarts = checkedCarts.length ? checkedCarts.every(item => item.quick_ship == 1) : false
      return isQuickShipCarts && this.cartQsTagInfo.isShowNewStyle ? this.cartQsTagInfo.text : false
    },
    //划线价 仅fr\pl\de合规站点展示
    crossedPrice(){
      if(!this.isSuggested) return null
      if(this.isSuggested == 'special_de') {
        return +this.cartInfo.suggested_sale_price?.amount && (+this.cartInfo.suggested_sale_price?.amount > +this.cartInfo.total_price_before_coupon?.amount) ? this.cartInfo.suggested_sale_price : null
      }
      return +this.cartInfo.crossed_price?.amount && (+this.cartInfo.crossed_price?.amount > +this.cartInfo.total_price_before_coupon?.amount) ? this.cartInfo.crossed_price : null
    },
    // 到手价 仅fr\pl合规站点展示
    finallyPrice(){
      if(!this.isSuggested) return null
      return +this.cartInfo.total_price_before_coupon?.amount && (+this.cartInfo.total_price_before_coupon?.amount > +this.cartInfo.totalPrice?.amount) ? this.cartInfo.totalPrice : null
    },
    showUnPaidOrder() {
      return this.isShowUnPaidOrder && this.cartList.length > 0 && this.loginStatus && this.unPaidOrderInfo?.orderData?.billno
    },
    CartUnpaidOrder() {
      return this.ABTParams?.CartUnpaidOrder?.param?.cart_unpaidOrder == 'on'
    },
    // 正常在售商品中是否有付费会员赠品
    hasClubGift() {
      const normalOnSaleCartList = this.cartList.filter(item => !this.soldoutIds.includes(item.id)) // 正常在售商品
      return normalOnSaleCartList.some(item => item.isSheinClubGiftItem)
    },
    showTotalRed(){
      if(this.isSuggested == 'special_de') {
        return this.crossedPrice
      }
      return this.crossedPrice || this.finallyPrice
    },
    showDetailEnter(){
      if(this.finallyPrice){
        return 0
      }
      if(this.isSuggested == 'special_de'){
        return (+this.cartInfo.originPrice?.amount > +this.cartInfo.totalPrice?.amount) && this.cartInfo.total_discount_price?.amount > 0 ? this.showTotalRed ? 2 : 3 : this.showPrimeVipOverQuota ? 3 : 0
      }
      if(this.crossedPrice){
        return 2
      }
      return this.showPrimeVipOverQuota ? 3 : 0
    },
    showCancelOrderTip() {
      return this.loginStatus && this.cartInfo.cartSumQuantity && this.ABTParams?.CancelledorderTip?.param?.cartcancelled_tip == 1 && !(this.CartUnpaidOrder && this.showUnPaidOrder)
    },
    priceDetailCheckedItemIds() {
      return this.cartsId.filter(id => this.cartItemMap[id]?.is_checked)
    },
    priceLabel() {
      if (!this.isExposePriceDetail) return ''
      return this.isSuggested ? `${this.language.SHEIN_KEY_PC_29824}:` : `${this.language.SHEIN_KEY_PC_29827}:`
    },
    isShowDiscountDetails() {
      if (!this.isExposePriceDetail) return false
      const totalDiscountPriceAmount = this.cartInfo?.total_discount_price?.amount || 0
      if (totalDiscountPriceAmount > 0 || this.showPrimeVipOverQuota || this.showDetailEnter != 0) {
        return true
      }
      return false
    },
    popoverName() {
      return this.checkoutButtonPopoverType == 'incentive' ? 'CheckoutIncentivePopover' : 'CheckoutFilterPopover'
    },
  },
  watch: {
    filterType: {
      handler(newVal, oldVal) {
        if(oldVal == 'none' && newVal != oldVal && CART_FILTER_CHECKOUT_TIP_SWITCH) {
          if(!this.filterLabelPopoverCache) this.filterLabelPopoverCache = new CheckoutTipCache()
          let memberId = UserInfoManager.get({ key: 'memberId', actionType: 'cart/filterType' })
          if(!memberId) return // 未登录则直接返回
          if(this.filterLabelPopoverCache.isExpireById(memberId)){
            this.setPopoverTypeWithPriority('filter')
            setTimeout(() => {
              this.setPopoverTypeWithPriority('')
            }, 3000)
            this.filterLabelPopoverCache.add(memberId)
          }
        }
      }
    },
    checkoutFreeShippingState: {
      handler() {
        this.checkoutFreeShippingExpose()
      },
      immediate: true,
    },
    'unPaidOrderInfo.orderData.billno': {
      handler(n, o) {
        if(n && !o) {
          let order = this.unPaidOrderInfo?.orderData || {}
          daEventCenter.triggerNotice({
            daId: '1-7-2-18',
            extraData: {
              scenes: 'cartUnpaidOrder',
              order_id: this.unPaidOrderInfo.orderData.billno,
              benefit_list: order?.total_discount_new?.amount > 0 ? 'dicount' : 'common',
            }
          })
        }
      },
      immediate: true,
    },
    showIncentivePopover(n) {
      if(n) {
        this.setPopoverTypeWithPriority('incentive')
      } else {
        this.setPopoverTypeWithPriority('')
      }
    },
  },
  beforeMount() {
    this.initLowerPriceUserEvt()
    this.initLowerPriceUserAbtTest()
    if (navigator && navigator.userAgent) {
      this.isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') ? true : false
    }
  },
  mounted(){
    window.cartsummary = this
    requestIdleCallback(() => {
      this.isIdle = true
    })
  },
  provide() {
    return {
      cutImg: this.cutImg
    }
  },
  methods: {
    ...mapMutations(['updateField']),
    ...mapActions(['asyncUpdateCartInfo', 'checkSoldOut', 'fetchModifyCartCheckStatus']),
    emitInitCheckout({ value = 0, msg = '', scenes = '', reason = '', options = {} }){
      daEventCenter.triggerNotice({
        daId: '1-7-2-3',
        extraData: {
          carts: this.cartInfo?.carts || [],
          value,
          msg,
          scenes,
          cartInfo: this.cartInfo,
          platformShippingAnalysisParam: this.mallShippingAnalysisParam,
          cartPromotionMapInfo: this.cartCheckoutPromotionMap,
          isPlatform: this.isPlatform,
          showQuickShip: this.showQuickShipTag,
          over_type: this.flashSaleOverLimitType,
          reason: reason,
          prime_flag: '-',
          abtest: this.abtTest?.sa || '',
          button_name: this.buttonNameClickType,
          click_from: 1,
          status: this.openControlLowPriceUser ? 0 : 1,
          coupon_change: '-',
          coupon_satisfied: this.couponInfo?.usableCouponList?.length > 0 ? 1 : 0,
          label_id: !isNaN(this.filterType)
            ? this.goodsFilterLabels.find(i => this.filterType == i.id)?.couponType
            : this.filterType,
          ...(options || {}),
          ...(this.lowerPriceParams || {}),
        }
      })
    },
    emitClickCheckoutEvt ({ value = 0, msg = '', scenes = '', reason = '', options = {} }) {
      this.emitInitCheckout({ value, msg, scenes, reason, options })
    },
    emitLimitAnlysis(flashList = [], limitList = []) {
      const flashReasonList = flashList.map(i => `10\`${i.flashSaleType}\`${i.reason > 2 ? 2 : i.reason}`)
      const limitReasonList = limitList.map(i => `24\`-\`${i.reason}`)
      daEventCenter.triggerNotice({
        daId: '1-7-2-15',
        extraData: {
          reason: [...flashReasonList, ...limitReasonList].join(',')
        }
      })
    },
    async goCheckout (opt) {
      if(this.checking) return
      this.checking = true
      web_checkout_button_click()

      if(!this.checkedQuantity){
        this.checking = false
        this.recordLog('1')
        this.$message({
          message: this.hasClubGift ? this.language.SHEIN_KEY_PC_30891 : this.language.SHEIN_KEY_PC_21653,
          type: 'error'
        })
        return
      }

      // 登录注册>闪购限购>限时折扣超限>打折超限 wiki.pageId=1137561716
      const validatorCallback = () => {
        // 打折限购以及闪购判断是否超过数量
        let LimitIds = [], limitTipData = [], newLimitTipData = []
        let promotionGroupList = this.cartList.filter(item => item.promotionGroupId)
        // SHEIN打折限购只关注勾选的商品
        if (!IS_RW) promotionGroupList = promotionGroupList.filter(item => item.is_checked)
        promotionGroupList.forEach( cartItem => {
          const itemPromotionInfo = this.integrityPromotionInfo[cartItem.promotionGroupId]
          const result = handleOrderOrSingleIsMeetOrOver(cartItem, itemPromotionInfo, true)
          if(result && !LimitIds.includes(cartItem.promotionGroupId)){
            LimitIds.push(cartItem.promotionGroupId)
          }
      
          if (!IS_RW && result) {
            const content = itemPromotionInfo.content || {}
            const tip = (content.tip || '')?.replace(/\d+/g, val => `<span>${val}</span>`)
            const title = this.language.SHEIN_KEY_PC_26637 || ''
            const limitTipItem = {
              tip: `<p style="text-transform: uppercase">${title}</p><div>${tip}</div>`,
              promotionGroupId: cartItem.promotionGroupId,
              goodsList: [cartItem],
              reason: result,
            }
            limitTipData.push(limitTipItem)
          }
        })
        if (!IS_RW && limitTipData.length) {
          // 将同活动类型的商品聚合
          limitTipData.forEach(item => {
            let promotionItem = newLimitTipData.find(child => child.promotionGroupId == item.promotionGroupId)
            if (promotionItem) {
              promotionItem.goodsList.push(...item.goodsList)
            } else {
              newLimitTipData.push(item)
            }
          })
        }

        // 常规闪购>新人闪购>特殊闪购>专区闪购>直播闪购>打折限购>品牌特卖
        // 品牌特卖也属于闪购，由于要脱离基于 flash_type 的排序逻辑, 故单独放在 brandSaleOverTip 里，手动排序到最后
        let flashSaleOverTip = []
        let brandSaleOverTip = []
        Object.keys(this.flashSaleGroupList).forEach(flash_promotion_id => {
          let flashSalePro = this.integrityPromotionInfo[flash_promotion_id] || {}
          let flashSaleProGroupList = flashSalePro.limit_mode == 0 ? this.flashSaleGroupList[flash_promotion_id]?.filter(item => item.is_checked) || [] : []
          if(flashSaleProGroupList.length) {
            let { result, flashSaleAndDiscountLimitHandleInfo } = handleOrderOrSingleIsMeetOrOver(null, { ...flashSalePro, list: flashSaleProGroupList }, true, true)
            if(result){
              let goodsList = flashSaleProGroupList
              if(result == 1) {
                let singleOverGoodSn = Object.entries(flashSaleAndDiscountLimitHandleInfo?.skcLimitNum || {}).filter(([, count]) => count > (flashSalePro?.cart_limit?.single || Number.MAX_SAFE_INTEGER)).map(i => i[0])
                goodsList = flashSaleProGroupList.filter(item => singleOverGoodSn.includes(item.product.goods_sn))
              }
              // PC端无直播闪购
              const item = {
                flashSaleType: flashSalePro.flash_type,
                tip: flashSalePro.content.tips.list[result],
                goodsList,
                reason: result,
                promotion_id: flash_promotion_id,
              }
              // 抽离 品牌特卖 活动单独处理
              if (item.flashSaleType == 6) {
                brandSaleOverTip.push(item)
              } else {
                flashSaleOverTip.push(item)
              }
            }
          }
        })
        flashSaleOverTip.sort((a, b) => {
          // 将专区闪购的type 4, 5 转换为 4, 以便排序
          const m = a.flashSaleType == 4 || a.flashSaleType == 5 ? 4 : a.flashSaleType
          const n = b.flashSaleType == 4 || b.flashSaleType == 5 ? 4 : b.flashSaleType
          let result = m - n
          if(result == 0 && m == 4) { // 专区闪购内部, 按照promotion_id升序排序
            result = Number(a.promotion_id) - Number(b.promotion_id)
          }
          return result
        })
        if(flashSaleOverTip.length || brandSaleOverTip.length){
          this.recordLog('4')
          if(!IS_RW) {
            this.flashSaleOverTip = [...flashSaleOverTip, ...newLimitTipData, ...brandSaleOverTip]
            this.showFlashSaleTip = true
            this.emitLimitAnlysis([...flashSaleOverTip, ...brandSaleOverTip], newLimitTipData)
          } else { // romwe继续走旧的超限提示逻辑, templte中对应组件也需要IS_RW判断
            if(flashSaleOverTip.length + brandSaleOverTip.length > 1) {
              this.flashSaleOverTip = [...flashSaleOverTip, ...brandSaleOverTip]
              this.showFlashSaleTip = true
            } else {
              this.$message({
                htmlString: `<div style="text-align: center">${(flashSaleOverTip[0] || brandSaleOverTip[0]).tip}</div>`,
                type: 'error',
                duration: 4000
              })
            }
          }
          daEventCenter.triggerNotice({
            daId: '1-7-2-4',
            extraData: {
              is_flashsale: [...flashSaleOverTip, ...brandSaleOverTip].map(item => item.flashSaleType).join(','),
            }
          })
          // 触发闪购超限弹窗后，如果在筛选状态下且筛选标签不为flash_sale，则清空筛选状态
          if (this.filterType !== 'none' && this.filterType !== 'flash_sale') {
            this.updateField({ key: 'filterType', value: 'none' })
          }
          this.emitInitCheckout({ value: 0, msg: 'over', reason: 3  })
          return
        }

        // SHEIN打折限购超限展示弹窗，ROMWE促销活动头抖动
        if (!IS_RW && limitTipData.length) {
          this.recordLog('6')
          this.flashSaleOverTip = newLimitTipData
          this.showFlashSaleTip = true
          this.emitLimitAnlysis([], newLimitTipData)
          return
        }

        if (IS_RW && LimitIds.length) {
          this.recordLog('6')
          cartEmphasizePromotionAll(LimitIds, { shake: true, changeColor: true, scroll: true })
          this.updateField({ key: 'filterType', value: 'none' })
          this.emitInitCheckout({ value: 0, msg: 'over', reason: 3  })
          return
        }

        let underPriceProducts = []
        Object.values(this.underPriceGroupList)?.forEach(item => {
          underPriceProducts = underPriceProducts.concat(item)
        })
        let underPriceOverItem = underPriceProducts.find(cartItem => cartItem.is_checked == 1 && (cartItem.product.product_promotion_info?.some(promo => promo.single_over == 1)))
        if(underPriceOverItem) {
          this.recordLog('7')
          this.$message({
            message: this.underPriceAbtValue ? this.language.SHEIN_KEY_PC_24849 : this.language.SHEIN_KEY_PC_24848,
            type: 'info',
            duration: 3000
          })
          this.updateField({ key: 'filterType', value: 'none' })
          this.emitInitCheckout({ value: 0, msg: 'over', reason: 3  })
          return
        }

        return true
      }

      let _this = this

      try {
        const noCheckStockCondition = getNoCheckStockCondition({ carts: _this?.cartInfo?.carts, lastCheckStockTime: this.lastCheckStockTime })
        let res = noCheckStockCondition ? 
          await new Promise(resolve => resolve({ code: 0 })) :
          await schttp({ url: '/api/cart/checkStock/get' })

        if (res?.code == 0) {
          const { checked_num, exceed_cart_num, limit_checked_num } = res?.info ?? {}
          
          if(exceed_cart_num > 0 && !noCheckStockCondition){
            this.recordLog('3')
            this.$message({
              message: this.template(limit_checked_num, checked_num - limit_checked_num, this.language.SHEIN_KEY_PC_25446),
              type: 'error',
              duration: 4000
            })
            this.checking = false
            daEventCenter.triggerNotice({
              daId: '1-7-2-5'
            })
            apiReport.report({
              apiPath: '/cart/checkout/billing_overrun'
            })
          } else if (res?.info?.outStockCarts?.length && !noCheckStockCondition) { //如果购物车商品有sold out
            this.recordLog('3')
            this.checkSoldOut({ outStockCarts: res.info.outStockCarts })
            this.updateField({ key: 'showSoldOutDialog', value: true })
            this.checking = false
            this.emitInitCheckout({ value: 0, msg: 'out of stock', reason: 2 })
          } else {
            // PWA逻辑平移
            !noCheckStockCondition && this.updateField({ key: 'lastCheckStockTime', value: Date.now() });

            (window.cartCheckLogin || UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })).then(function(data) {
              if (data.member_id) {
                if (!validatorCallback()) {
                  _this.checking = false
                  return
                }

                opt && opt == 'login' && window.sessionStorage && window.sessionStorage.setItem('page_from', 'login')
                let prime_product_code = ''
                if (_this.openControlLowPriceUser == 1) {
                  _this.recordLog('8')
                  // 低客单强拦截
                  _this.emitInitCheckout({ value: 0, msg: 'UnArrived Low Price', reason: 4 })
                  _this.lowerPriceParams = {}
                  _this.checking = false
                  _this.showLowPriceUser = true
                } else if (_this.openControlLowPriceUser == 2 && opt != 'lowerPriceUser') {
                  _this.recordLog('8')
                  // 低客单弱拦截, 使用opt避免二次拦截
                  _this.emitInitCheckout({ value: 0, msg: 'UnArrived Low Price', reason: 4 })
                  _this.lowerPriceParams = {}
                  _this.checking = false
                  _this.showLowPriceUser = true
                } else {
                  setTimeout(() => {
                    _this.checking = false
                  }, 6000)
                  _this.recordLog('0')
                  _this.emitInitCheckout({ value: 1 })
                  // const queryString = stringifyQueryString({
                  //   queryObj: removeNullAttr({
                  //     prime_product_code,
                  //     auto_coupon: getAutoCouponCodes(_this.cartInfo.coupon_infos)
                  //   })
                  // })
                  // window.location.href = host + '/checkout?' + queryString
                  if(window.fetchCheckout) {
                    try {
                      window.fetchCheckout.then(() => {
                        window.fetchCheckout = null
                        window.location.href = getToCheckoutUrl({ prime_product_code, coupon_infos: _this?.cartInfo?.coupon_infos })
                      }).catch(()=>{
                        window.location.href = getToCheckoutUrl({ prime_product_code, coupon_infos: _this?.cartInfo?.coupon_infos })
                      })
                    } catch(e) {
                      window.location.href = getToCheckoutUrl({ prime_product_code, coupon_infos: _this?.cartInfo?.coupon_infos })
                    }
                  } else {
                    window.location.href = getToCheckoutUrl({ prime_product_code, coupon_infos: _this?.cartInfo?.coupon_infos })
                  }
                }
              } else {
                _this.recordLog('2')
                _this.emitInitCheckout({ value: 0, msg: 'Not login', reason: 1 })
                
                _this.checking = false
                
                cartLoginView.login({ type: 'checkout', beforeCartSumQuantity: _this.cartInfo.cartSumQuantity, cb: _this.loginModalSucCb })
              }
            })
          }
        } else {
          this.checking = false
          this.recordLog('3')
          this.emitInitCheckout({ value: 0, msg: res.msg })
          if (['500302', '500306', '300417', '500303'].includes(res.code)) {
            this.$message({
              message: this.template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, res.tips),
              type: 'error',
              duration: 4000
            })
          } else {
            res.tips && this.$message({
              message: res.tips,
              type: 'error'
            })
          }
        }
      } catch (e) {
        this.checking = false
        this.recordLog('500')
        web_checkout_button_error(e)
      }
    },
    loginModalSucCb: function(result, res) {
      this.updateField({ key: 'loginStatus', value: true })
      if(res.code == 0){
        this.asyncUpdateCartInfo({ cartInfo: res.info })
        this.goCheckout('login')
      }
    },
    initLowerPriceUserEvt () { // mini购物车结算时复用低客单拦截弹窗
      let fromPage = getQueryString({ key: '_fromPage' })
      if (fromPage == 1 && this.openControlLowPriceUser == 1) {
        this.showLowPriceUser = true
      } else if (fromPage == 1 && this.openControlLowPriceUser == 2) {
        this.showLowPriceUser = true
      } else if (fromPage == 0) { // 进入购物车后, 自动checkout
        this.goCheckout()
      }
    },
    initLowerPriceUserAbtTest () {
      let lowPriceUser = this.ABTParams?.LowpriceUser || {}
      // eslint-disable-next-line @shein-aidc/abt/abt
      let abtTest = abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'LowpriceUser': lowPriceUser } })
      this.abtTest = abtTest || {}
    },
    closeLowPriceUserEvt ({ click_type = '', click_from = '', click_status = '' } = {}) {
      this.showLowPriceUser = false
      if (click_type == 'lowerPriceUser') {
        this.lowerPriceParams = { click_from, status: click_status }
        this.goCheckout(click_type)
      }
    },
    recordLog(type){
      web_checkout_button_jump(type)
    },
    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.locals.RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    checkoutFreeShippingExpose() {
      daEventCenter.triggerNotice({
        daId: '1-7-2-18',
        extraData: {
          type: Number(!!this.checkoutFreeShippingState)
        }
      })
    },
    transformImg: transformImg,
    template: template,
    htmlDecode: htmlDecode,
    // 触发超限弹窗后，如果在筛选状态下且当前点击商品不属于当前筛选标签，则清空筛选状态
    handleFilter(item) {
      if (this.filterType !== 'none' && !showFilterItem(item, this.filterType, this.targetCouponInfo, this.goodsFilterLabels)) {
        this.updateField({ key: 'filterType', value: 'none' })
      }
    },
    updateCheckedItemIds(val) {
      if(val) {
        this.checkedItemIds = val
        return
      }
      this.checkedItemIds = this.cartsId.filter(id => this.cartItemMap[id]?.is_checked)
    },
    async handleUpdateOrder() { // 未支付订单 客户端渲染兜底 pageId=1350582498
      let data = await queryLatestUnPaidOrderInfo()
      if(data?.orderData?.billno) {
        this.updateField({ key: 'unPaidOrderInfo', value: data })
      }
    },
    handleViewOrder(billno, queryParams) {
      let url = `/checkout/checkout_again/${billno}?${stringifyQueryString({ queryObj: queryParams })}`
      this.isShowUnPaidOrder = false
      window.open(`${location.origin}${url}`)
    },
    setPopoverTypeWithPriority(value) {
      if(value == '' || this.checkoutButtonPopoverType == '') {
        this.checkoutButtonPopoverType = value
        return
      }
      const Priority = ['newUser', 'incentive', 'filter']
      let targetOrder = Priority.indexOf(value)
      let currentOrder = Priority.indexOf(this.checkoutButtonPopoverType)
      if(targetOrder >= 0 && targetOrder < currentOrder) {
        this.checkoutButtonPopoverType = value
      }
    },
    handleIncentiveProductListDialog(val) {
      if(typeof val === 'undefined') {
        this.updateField({
          key: 'showIncentiveProductListDialog',
          value: false
        })
        return
      }
      this.updateField({
        key: 'showIncentiveProductListDialog',
        value: val
      })
      if(!val) {
        if(this.showIncentiveTimes < 3  && (this.inducementSortList?.length || 0) - 1 >= this.showIncentiveTimes) {
          if(this.incentivePopoverSwitchTimer) {
            clearTimeout(this.incentivePopoverSwitchTimer)
            this.updateField({
              key: 'incentivePopoverSwitchTimer',
              value: null
            })
          }
          const incentiveGapTime = this.incentiveTimeConfig?.incentiveGapTime || 1000 * 60
          this.updateField({
            key: 'incentivePopoverSwitchTimer',
            value: setTimeout(() => {
              this.updateField({
                key: 'showIncentivePopover',
                value: true
              })
            }, incentiveGapTime)
          })
        }
      }
    },
    handleCloseIconClick() {
      // console.log('debugger trigger before close popover', e)
      return // vue3的popover 组件不支持icon的关闭事件, 不可启用下方代码, 对应埋点事件无法实现
      /*
      if(this.incentivePopoverKeepTimer) {
        clearTimeout(this.incentivePopoverKeepTimer)
        this.updateField({
          key: 'incentivePopoverKeepTimer',
          value: null
        })
        this.updateField({
          key: 'showIncentivePopover',
          value: false
        })
      }
      if(this.showIncentiveTimes < 3 && (this.inducementSortList?.length || 0) - 1 >= this.showIncentiveTimes) {
        const incentiveGapTime = this.incentiveTimeConfig?.incentiveGapTime || 1000 * 60
        if(this.incentivePopoverSwitchTimer) {
          clearTimeout(this.incentivePopoverSwitchTimer)
          this.updateField({
            key: 'incentivePopoverSwitchTimer',
            value: null
          })
        }
        const gapTimer = setTimeout(() => {
          this.updateField({
            key: 'showIncentivePopover',
            value: true
          })
        }, incentiveGapTime)
        this.updateField({
          key: 'incentivePopoverSwitchTimer',
          value: gapTimer
        })
      }
      daEventCenter.triggerNotice({
        daId: '1-7-3-62',
        extraData: {
          actual_point: this.incentivePopoverContent?.type
        }
      })
       */
    },
  }
}
</script>
<style lang="less">
.c-order-summary .point-tip {
  color: #222222;
  text-align: right;
  .point-tip-content{
    color: @sui_color_gray_dark3;
    span{
      display: block;
      margin-bottom: 8px;
    }
  }
  .c-icon-tip{
    color: #222222;
    position: relative;
    display: inline-flex;
    padding: 5px;
    align-items: center;
  }
  .prime-point {
    display: inline-flex;
    align-items: center;
  }
  .point-prime-vip{
    color: #C96E3F;
  }
  .point-prime-vip_a {
    color: #873c00;
  }
  .point-red {
    color: @sui_color_highlight;
  }
  .point-black {
    color: #222;
  }
}
.c-check{
  margin-bottom: 30px;
  .cart-summary-title{
    font-family: Arial-Black, Arial /* rw: Adieu*/;
  }
  .font-bold {
    font-family: Adieu-Bold, Adieu;
    font-weight: bold;
  }
  .icon_doubt {
    color: @sui_color_gray_dark1;
  }
  &.c-summary-fixed{
    position: fixed;
    top: 0;
    .right(0);
    z-index: @zindex-header;
    background: #fff;
    padding-top: 10px;
  }
  .check-title>h4 {
    font-size: 20px;
    text-transform: capitalize;
    display: inline-block;
  }
}

.flashSale-over-tip{
  text-align: left;
  .tip-item + .tip-item{
    margin-top: 18px;
  }
}
</style>

<style lang="less" scoped>
.check-btn{
  :deep(.sui-popover__trigger) {
    width: 100%;
  }
}
.c-check__new-cart-right {
  width: 100%;
}
.c-check__cart-summary{
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 60vh;
  background: #fff;
  .check-title{
    padding-bottom: 0;
    border: none;
    margin-bottom: 8px;
    font-family: Arial-Black, Arial;
  }
  .check-subtitle {
    margin-bottom: 10px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.30);
  }
  .summary-item {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before, &::after{
      display: none;
    }
    .price-label {
      flex-shrink: 99999;
      min-width: 77px;
      margin-right: 12px;
      font-size: 14px;
      color: @sui_color_gray_dark1;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .total{
      font-family: Arial-Black, Arial;
      font-size: 24px;
      padding: 0 4px;
      color:#222222;
    }
    .total-red{
      color: @sui_color_discount;
    }
    .crossed-price{
      text-decoration: line-through;
    }
    .crossed-price-text{
      font-size: 13px;
      color: @sui_color_gray_dark3;
    }
  }
  .summary-tax-info {
    text-align: right;
    margin-bottom: 8px;
  }
  .point-tip,.free-ship-tip,.special-gift-tip{
    font-size: 14px;
    margin-bottom: 16px;
    &:hover{
      color: #222222;
      /* stylelint-disable-next-line selector-max-specificity */
      .point-shein{
        color: #222222;
        /* stylelint-disable-next-line selector-max-specificity */
        i{
          color: #222222;
        }
      }
    }
    .point-shein{
      border: none;
      /* rw:begin */
      color: #767676;
      font-weight: normal;
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover{
        text-decoration: none;
        color: #222222;
      }
    }
  }
  .point-tip { 
    margin-bottom: unset;
    padding-top: 8px;
  }
  .check-btn{
    .coupon-point-tip{
      color: #767676;
      text-align: left;
      margin-top: 10px;
      font-size: 12px;
    }
    .free-shipping-tip {  
      position: absolute;
      top: -9px;
      right: -5px;
      &::after {
        content: ' ';
        position: absolute;
        top: 100%;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 0;
        border-color: #16533A transparent transparent transparent;
      }
    }
  }
  .sold-out-tip{
      margin-top: 24px;
      border: none;
      background: #FFF1D5 /* rw: #FFF7F0 */;
      font-size: 14px;
      color: #222;
      padding: 16px;
      line-height: 16px;
      .flexbox();
      .space-between();
      i{
        color: #FAAD14;
        font-size: 16px;
        margin-right: 12px;
      }
    }
}
.c-order-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.accept-bank{
  background: #fff;
  padding-top: 16px;
  padding-left: 16px;
  margin-top: 10px;
  >p{
    margin-bottom: 15px;
    font-size: 20px;
    color: #222;
    text-transform: capitalize;
    font-weight: bold;
    font-family: Arial-Black, Arial;
  }
  .c-img__con-m{
    width: 51px;
    height: 34px;
  }
  .bank-img{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding-bottom: 15px;
  }
}
.summary-price{
  display: flex;
  align-items: baseline;
  .summary-price-crossed{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    color: #222;
    margin-right: 2px;
  }
}
.incentive-check-btn {
  ::v-deep .sui-popover__content {
    max-width: 368px;
    padding: 8px 20px 8px 8px;
  }
}
.incentive-button {
  display: flex;
  flex-direction: column;
  line-height: normal !important;/* stylelint-disable-line declaration-no-important */
  align-items: center;
  justify-content: center;
  gap: 2px;
  .checkout-btn-content {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    box-sizing: border-box;
  }
}
</style>
