<template>
  <div
    v-expose="analysisData('1-7-3-31')"
  >
    <template v-if="soldoutItems.length == 1">
      <CartSoldOutItem
        class="cart-soldout-item"
        :item="soldoutItems[0]"
        :index="cartsId.indexOf(soldoutItems[0].id)"
      />
    </template>
    <div
      v-else-if="soldoutItems.length > 1"
      class="swiper-box"
    >
      <CartItemSwiper
        :items="soldoutItems"
        :locals="locals"
      >
        <template #default="{ item }">
          <div class="swiper-item">
            <cartListItemImg
              :item="item"
              :is-first-page="isFirstPage"
              :hide-corner="isNewCartDiscountStyle"
            />
            <p
              v-if="getFindSimilarShow(item)"
              v-expose="{
                id: '1-7-3-29',
                data: {
                  main_goods_id: item.product.goods_id,
                },
              }"
              v-tap="{
                id: '1-7-3-30',
                data: {
                  main_goods_id: item.product.goods_id,
                },
              }"
              class="soldout-similar"
              @click="handleShowSimilarModal(item)"
            >
              {{ language.SHEIN_KEY_PC_16963 }}
            </p>
          </div>
        </template>
      </CartItemSwiper>
    </div>
  </div>
</template>

<script>
import cartListItemImg from '../cartListItemImg.vue'
import CartSoldOutItem from './CartSoldOutItem.vue'
import CartItemSwiper from './CartItemSwiper.vue'
import SimilarPopover from '../../../../common/similar_products'
import { daEventCenter } from '../../../../../services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-7-3' })
import { mapGetters, mapState } from 'vuex'
import { isValidCartItem } from 'public/src/pages/cart_new/utils/index.js'

export default {
  name: 'CartSoldOutItems',
  components: {
    cartListItemImg,
    CartSoldOutItem,
    CartItemSwiper,
  },
  props: {
    soldoutItems: {
      type: Array,
      default(){
        return []
      }
    },
    cartsId: {
      type: Array,
      default(){
        return []
      }
    },
  },
  computed: {
    ...mapState(['language', 'ABTParams', 'isPlatform', 'locals']),
    ...mapGetters(['isNewCartDiscountStyle']),
    isFirstPage(){
      if(this.soldoutItems.length){
        return this.cartsId.indexOf(this.soldoutItems[0].id) < 6
      }
      return false
    }
  },
  methods: {
    getFindSimilarShow(item) {
      if (item.isSheinClubGiftItem) return false
      return this.ABTParams?.SheinFindSimilar?.param?.FindSimilarShow == 'Show'
    },
    handleShowSimilarModal(item) {
      SimilarPopover.showModal(item.product, {
        similarFrom: 'out_of_stock',
      })
    },
    analysisData(id) {
      const goodsInfo = []
      this.soldoutItems.forEach((item) => {
        let saleStatus = ''
        if (isValidCartItem(item, this.isPlatform)) {
          saleStatus = 0
        } else {
          saleStatus = +item.quantity > +item.realTimeInventory ? 2 : 1
        }

        let member_gift_info = '-'
        if (item.isSheinClubGiftItem) {
          member_gift_info = item.isInvalid ? 'invalid' : 'no_stock'
        }

        goodsInfo.push(`${item.product.goods_id}\`${saleStatus}\`${member_gift_info}`)
      })
      return {
        id: id,
        data: {
          goods_info: goodsInfo.join(','),
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.swiper-box {
  color: #222;
  padding: 8px 16px;
  background-color: #FFF;
  position: relative;
  .swiper-item {
    width: 120px;
    >*:not(:first-child) {
      margin-top: 5px;
    }
    .soldout-similar {
      margin-top: 4px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;
      background-color: @sui_color_gray_weak2;
      color: @sui_color_gray_dark1;
      cursor: pointer;
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
</style>
